import React from 'react'

const TruncatedString = ({ style, str, startChars, endChars }) => {
  const shortenString = (str) => {
    if (str.length <= startChars + endChars) {
      return str
    }
    const start = str.slice(0, startChars)
    const end = str.slice(-endChars)
    return `${start}...${end}`
  }

  return <span style={{ ...style, fontWeight: '700' }}>{shortenString(str)}</span>
}

export default TruncatedString
