import React, { createContext, useState, useEffect } from 'react'

// Создаём контекст для темы
export const ThemeContext = createContext()

// Провайдер для управления темой
export const ThemeProvider = ({ children }) => {
  const [isLightTheme, setIsLightTheme] = useState(true) // По умолчанию светлая тема

  const setupColorTheme = () => {
    if (window.Telegram.WebApp.colorScheme === 'dark') {
      setIsLightTheme(false)
    } else {
      setIsLightTheme(true)
    }
  }

  window.Telegram.WebApp.onEvent('themeChanged', () => {
    setupColorTheme()
  })

  useEffect(() => {
    setupColorTheme()
  }, [])

  // Передаём значение темы и функцию для её изменения
  return (
    <ThemeContext.Provider value={{ isLightTheme, setIsLightTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
