import { useState, useRef, useEffect, useContext } from 'react'
import { Popper, List, Cell } from '@telegram-apps/telegram-ui'
import { settingsPayment, settingsChevronVertical, settingsPopupAction } from '../../../res/icons'
import { ThemeContext } from '../../../lib'
import { LocalizedStrings } from '../../../res/localization'

export const SettingsPaymentMethodPopUp = (props) => {
  const { selectedMethod, setSelectedMethod, paymentMethods, currentActiveId, setCurrentActiveId } = props
  const targetRef = useRef(null)
  const popperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isLightTheme } = useContext(ThemeContext)

  const handleClickCapture = () => {
    setIsOpen(prev => !prev)
  }

  const handleSelect = (id) => {
    setCurrentActiveId(id)
    setSelectedMethod(id)
  }

  const handleClickOutside = (event) => {
    if (
      targetRef.current &&
      !targetRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div>
      <div onClickCapture={handleClickCapture} ref={targetRef}>
        <Cell
          before={<img src={settingsPayment} alt="Payment" />}
          after={<img src={settingsChevronVertical} alt="Chevron" />}
        >
          {LocalizedStrings.paymentMethod}
        </Cell>
      </div>
      {isOpen && (
        <Popper
          style={{ paddingLeft: '30px', marginTop: '-13px' }}
          arrowProps={{
            style: {
              display: 'none'
            }
          }}
          targetRef={targetRef}
          ref={popperRef}
          placement="bottom-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -15] // Raised the pop-up by 15 pixels
              }
            }
          ]}
        >
          <div style={{
            backgroundColor: (isLightTheme ? 'rgba(255, 255, 255, 1)' : 'rgba(49, 49, 49, 1)'),
            borderRadius: '16px',
            width: '325px',
            height: 'auto',
            overflowY: 'auto',
            margin: '0 auto',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
          }}>
            <List style={{ paddingRight: '0px' }}>
              {paymentMethods.map((method, index) => (
                <>
                  <Cell
                    style={elemStyle}
                    after={method.id === currentActiveId ? <img src={settingsPopupAction} alt="Action" /> : null}
                    onClick={() => handleSelect(method.id)}
                    description={method.description}
                  >
                    {method.label}
                  </Cell>
                  {index < paymentMethods.length - 1 && (
                    <hr style={{
                      border: 'none',
                      borderTop: (isLightTheme ? '0.5px solid rgba(0, 0, 0, 0.15)' : '0.5px solid rgba(255, 255, 255, 0.05)'),
                      marginTop: '8px'
                    }} />
                  )}
                </>
              ))}
            </List>
          </div>
        </Popper>

      )}
    </div>
  )
}

const elemStyle = {
  marginLeft: '-16px',
  height: '44px',
  fontSize: '14px',
  lineHeight: '18px'
}
