import { LocalizedStrings } from '../../../../res/localization'
import {
  Subheadline,
  Button,
  Placeholder,
  Badge,
  Title
} from '@telegram-apps/telegram-ui'
import { getPriceByCurrencyString, getImageUrl } from '../../../../lib'
import customStorage from '../../../../CustomStorage'
import { orderTea } from '../../../../res/icons'
import styles from '../styles.module.css'
import ImageLoader from 'react-imageloader'
import { useState } from 'react'

export const CatalogItem = (props) => {
  const { item, plusProductCount, minusProductCount, quantity, isLightTheme } = props

  const [isIncreasePressed, setIsIncreasePressed] = useState(false)
  const [isDecreasePressed, setIsDecreasePressed] = useState(false)
  const [isAddPressed, setIsAddPressed] = useState(false)

  const handleIncreasePress = () => setIsIncreasePressed(true)
  const handleIncreaseRelease = () => setIsIncreasePressed(false)

  const handleDecreasePress = () => setIsDecreasePressed(true)
  const handleDecreaseRelease = () => setIsDecreasePressed(false)

  const handleAddPress = () => setIsAddPressed(true)
  const handleAddRelease = () => setIsAddPressed(false)

  const isProductUnavailable = (item.warehouse.availability <= quantity) || (item.warehouse.availability === 0)
  const increaseProductCount = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(50) // Вибрация на 50 мс
    }
    plusProductCount(item)
  }

  const decreaseProductCount = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(50) // Вибрация на 50 мс
    }
    minusProductCount(item)
  }

  function preloader () {
    return <img height={78} width={78} src={orderTea}></img>
  }

  return (
    <div className={styles.CatalogItemWrapper}>
      <Badge
        style={{
          zIndex: 1,
          position: 'relative',
          top: 16,
          right: 0,
          marginLeft: '70%',
          visibility: quantity > 0 ? 'visible' : 'hidden',
          color: (isLightTheme ? '#007AFF' : '#2990FF')
        }}
        mode="white"
        type="number"
      >
        {quantity}
      </Badge>
      <Placeholder
        className={styles.CatalogItemContainer}
        action={
          <div className={styles.CatalogItemActionWrapper}>
            {(quantity === 0)
              ? (
                <Button
                  size="m"
                  disabled={isProductUnavailable}
                  onClick={increaseProductCount}
                  onMouseDown={handleAddPress}
                  onMouseUp={handleAddRelease}
                  onMouseLeave={handleAddRelease}
                  className={isProductUnavailable ? styles.CatalogItemActionAddUnavailable : (isAddPressed ? styles.CatalogItemActionAddPressed : styles.CatalogItemActionAdd)}
                >
                  {(isProductUnavailable) ? LocalizedStrings.catalogProductUnavailable : LocalizedStrings.catalogAddProduct}
                </Button>
                )
              : (
                <div className={styles.CatalogItemActionOperations}>
                  <Button
                    className={isDecreasePressed ? styles.CatalogItemActionDecreasePressed : styles.CatalogItemActionDecrease}
                    size="m"
                    onClick={decreaseProductCount}
                    onMouseDown={handleDecreasePress}
                    onMouseUp={handleDecreaseRelease}
                    onMouseLeave={handleDecreaseRelease}
                  >
                    <Title level="2" weight="2">
                      -
                    </Title>
                  </Button>
                  <Button
                    size="m"
                    disabled={isProductUnavailable}
                    onClick={increaseProductCount}
                    onMouseDown={handleIncreasePress}
                    onMouseUp={handleIncreaseRelease}
                    onMouseLeave={handleIncreaseRelease}
                    className={isProductUnavailable ? styles.CatalogItemActionIncreaseUnavailable : (isIncreasePressed ? styles.CatalogItemActionIncreasePressed : styles.CatalogItemActionIncrease)}
                  >
                    <Title level="2" weight="2">
                      +
                    </Title>
                  </Button>
                </div>
                )}
          </div>
        }
        header={
          <Subheadline
            style={{
              // backgroundColor: "red",
              width: '120px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: (isLightTheme ? '#000' : '#fff'),
              fontSize: '13px'
            }}
            level="1"
            weight="1"
          >
            {item.caption}
          </Subheadline>
        }
        description={
          <Subheadline style={{
            width: '123px',
            color: (isLightTheme ? '#000' : '#fff'),
            lineHeight: '18px',
            position: 'relative',
            top: -8,
            left: -1,
            fontSize: '13px'
          }} level="2" weight="2">
            {getPriceByCurrencyString(item.price) + (item.addonProps !== null ? (' • ' + (item.addonProps.weight)) : '')}
          </Subheadline>
        }
      >
        <ImageLoader
          imgProps={{ height: 78, width: 78 }}
          src={getImageUrl(item.personalImage ? item.personalImage : item.article.image)}
          className={styles.CatalogItemImage}
          alt="Product picture"
          preloader={preloader}
        >
          <img height={78} width={78} src={orderTea}></img>
        </ImageLoader>
      </Placeholder>
    </div>
  )
}
