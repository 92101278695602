import { Cell, Subheadline } from '@telegram-apps/telegram-ui'
import { orderTea, orderDeleteGood } from '../../../../../res/icons'
import styles from './styles.module.css'
import { getCurrencyRegularString, getPriceByCurrency, getImageUrl } from '../../../../../lib'
import ImageLoader from 'react-imageloader'
import { LocalizedStrings } from '../../../../../res/localization'
import { PackageCountPopUp } from './PackageCountPopUp'

export const ViewOrderItem = (props) => {
  const { item, isLightTheme, onDeleteItem, onSelectPackageWeight } = props
  console.log('ViewOrderItem', item)

  function preloader () {
    return <img height={55} width={55} src={orderTea}></img>
  }

  const handleDeleteItem = () => {
    onDeleteItem(item.good.id)
  }

  const handleSelectPackageWeight = (count) => {
    onSelectPackageWeight(item.good.id, count)
  }

  return (
<div className={styles.container}>
    <div className={styles.gridContainer}>
        <div className={styles.flexContainer}>
            <ImageLoader
                imgProps={{ height: 55, width: 55 }}
                src={getImageUrl(item.good.personalImage ? item.good.personalImage : item.good.article.image)}
                className={styles.productImage}
                alt="Product picture"
                preloader={preloader}
            >
                <img height={55} width={55} src={orderTea}></img>
            </ImageLoader>

            <div className={styles.textContainer}>
                <div className={styles.caption}>{item.good.caption}</div>
                <div className={styles.description}>
                    {item.good && item.good.description}
                </div>
                <div className={styles.additionalInfo}>
                    {item.good.article && (item.good.teaCategory !== null ? (item.good.teaCategory + LocalizedStrings.teaCategory) : '')}
                </div>
                <PackageCountPopUp
                count={item.count}
                onePackageWeight={item.good.addonProps.weight}
                availableCount={item.good.warehouse.availability}
                onSelectPackageWeight={handleSelectPackageWeight}
                ></PackageCountPopUp>
            </div>
        </div>
        <div className={styles.price}>
            {getCurrencyRegularString(
              (item.count * getPriceByCurrency(item.good.price)).toFixed(2)
            )}
        </div>
        <div style={{ height: '118%', width: '0.5px', backgroundColor: 'rgba(0, 0, 0, 0.07)', marginLeft: '9vw' }}></div>
        <div>
            <img src={orderDeleteGood} onClick={handleDeleteItem}></img>
        </div>

    </div>
</div>
  )
}
