import { create } from 'zustand'

export function createOrdersSlice (set) {
  return {
    orders: [],
    addOrder: (order) => set((state) => ({ orders: [...state.orders, order] })),
    removeOrder: (id) => set((state) => ({
      orders: state.orders.filter((order) => order.order_id !== id)
    })),
    updateOrder: (id, order) => set((state) => ({
      orders: state.orders.map((o) => (o.order_id === id ? order : o))
    })),
    setOrders: (orders) => set(() => ({ orders }))
  }
}

export const useOrdersStore = create((set) => createOrdersSlice(set))
