import z from 'zod'

const deliveryInfoSchema = z.object({
  address: z.string()
    .min(1, { message: 'Address is required' })
    .min(10, { message: 'Address is too short' }),
  country: z.string()
    .min(1, { message: 'Country is required' })
    .min(3, { message: 'Country is too short' }),
  city: z.string()
    .min(1, { message: 'City is required' })
    .min(2, { message: 'City is too short' }),
  postcode: z.string()
    .min(1, { message: 'Postcode is required' })
    .min(5, { message: 'Postcode is too short' })
})

const deliveryInfoEditSchema = deliveryInfoSchema.omit({ state: true }).partial()

export { deliveryInfoSchema, deliveryInfoEditSchema }
