import {
  Subheadline,
  Button
} from '@telegram-apps/telegram-ui'
import BannerBackground from '../../../res/images/banner-bg.png'
import BannerLogo from '../../../res/images/2323-time-logo.svg'
import styles from './styles.module.css'
import { LocalizedStrings } from '../../../res/localization'

export const SettingsTopBanner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BannerBackground})`,
        backgroundColor: '#232220',
        backgroundSize: '428px 477px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center -85px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0',
        borderRadius: '16px',
        width: 'auto',
        padding: '16px 0'
      }}
    >
      <div className={styles.TopBannerTextWrapper}>
        <Subheadline
          className={styles.TopBannerTitleLeft}
          level={1}
          weight={1}
        >
          {LocalizedStrings.join}
        </Subheadline>
        <img
          alt="banner"
          className={styles.TopBannerLogo}
          src={BannerLogo}
        ></img>
        <Subheadline
          className={styles.TopBannerTitleRight}
          level={1}
          weight={1}
        >
          {LocalizedStrings.community}
        </Subheadline>
      </div>
      <Subheadline
        className={styles.TopBannerSubtitle}
        level={2}
        weight={3}
      >
        {LocalizedStrings.exploreTeaAndCollabs}
      </Subheadline>
      <Button
        size="s"
        onClick={() => window.open('https://t.me/one2323xChkP', '_blank')}
        className={styles.TopBannerJoinCommunity}
      >
        {LocalizedStrings.joinCommunity}
      </Button>
    </div>
  )
}
