import z from 'zod'

const receiverSchema = z.object({
  name: z.string()
    .min(1, { message: 'Name is required' })
    .min(2, { message: 'Name is too short' }),
  phone: z.string()
    .min(1, { message: 'Phone is required' })
    .min(11, { message: 'Phone is too short' }),
  email: z.string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Email is invalid' })
})

const receiverEditSchema = receiverSchema.partial()

export { receiverSchema, receiverEditSchema }
