import { Tabbar, Badge, Snackbar, Button } from '@telegram-apps/telegram-ui'
import {
  settingsCatalog,
  settingsOrders,
  settingsSettings,
  settingsCatalogClicked,
  settingsOrdersClicked,
  settingsSettingsClicked,
  infoIcon
} from '../../res/icons'

import { useState, useEffect, useContext } from 'react'
// import CatalogMain from "../Catalog/Main/CatalogMain";
// import OrdersMain from "../Orders/Main/OrdersMain";
// import SettingsMain from "../Settings/Main/SettingsMain";
import { LocalizedErrorStrings, LocalizedStrings } from '../../res/localization'
import { Outlet, useMatch, useLocation, useNavigate } from 'react-router-dom'

// import { retrieveLaunchParams } from '@telegram-apps/sdk';
// import axios from "axios";
import customStorage from '../../CustomStorage'
import { Currencies, ThemeContext } from '../../lib'
import { api } from '../../api'
import { useUserStore, useCartStore } from '../../store'
import { object } from 'zod'

const Default = (_props) => {
  const tabs = [
    { id: 1, text: LocalizedStrings.catalog, iconNormal: settingsCatalog, iconClicked: settingsCatalogClicked, to: '/' },
    { id: 2, text: LocalizedStrings.orders, iconNormal: settingsOrders, iconClicked: settingsOrdersClicked, to: '/orders' },
    { id: 3, text: LocalizedStrings.settings, iconNormal: settingsSettings, iconClicked: settingsSettingsClicked, to: '/settings' }
  ]

  // const [IsClientValidated, setIsClientValidated] = useState(false);
  const location = useLocation()
  const { isLightTheme } = useContext(ThemeContext)
  // const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null)
  const { isLoading, init: initUser, isValid } = useUserStore(store => store)
  const { cart, initCart } = useCartStore(store => store)
  // const isTabBarVisible = useCartStore(store => Object.keys(store.cart).length === 0)
  const [isTabBarVisible, setIsTabBarVisible] = useState(true)

  // function getTabContent(tab) {
  //     switch (tab) {
  //         case 1: return <CatalogMain tabBarVisibility={setTabBarVisibility} canGetProducts={IsClientValidated} />;
  //         case 2: return <OrdersMain ordersCount={setOrdersCount} />;
  //         case 3: return <SettingsMain />;
  //         default: return null;
  //     }
  // }
  const match = useMatch(location.pathname)
  const navigate = useNavigate()
  customStorage.setItem('currency', Currencies.TON)

  useEffect(() => {
    api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
          setApiError(error.response.data.error.message)
        }
        return Promise.reject(error)
      }
    )
  }, [])

  useEffect(() => {
    initUser((err) => {
      // setApiError(err)
      setApiError(err?.message ?? 'init user failed')
    })
  }, [initUser])

  useEffect(() => {
    if (isValid) {
      console.log('is valid')
      initCart((err) => {
        setApiError(err?.message)
      })
    }
  }, [isValid])

  useEffect(() => {
    if (location.pathname === '/vieworder' || location.pathname === '/orderplaced' || location.pathname === '/orderdetails') {
      setIsTabBarVisible(false)
      if (location.pathname === '/orderplaced') {
        initCart((err) => {
          setApiError(err?.message)
        })
      }
    } else if (location.pathname === '/' && Object.keys(cart).length !== 0) {
      setIsTabBarVisible(false)
    } else {
      setIsTabBarVisible(true)
    }
  }, [location, Object.keys(cart).length])

  // useEffect(() => {
  //   console.log("process.env.NODE_ENV: ", process.env.NODE_ENV);
  //   if (process.env.NODE_ENV !== 'development') {
  //     // Код валидации юзера, работает только на прод-версии
  //     console.log('Started in production build');

  //     const { initDataRaw, initData } = retrieveLaunchParams();
  //     console.log('initDataRaw: ', initDataRaw);
  //     console.log('initData: ', initData);

  //     validateClient(initDataRaw);
  //   }
  //   else {
  //     api.post('/api/auth', {
  //       login: "admin",
  //       password: "92668751"
  //     }).then(response => {
  //       customStorage.setItem('token', response.data.accessToken)
  //       api.get('/api/delivery-address').then(response => {
  //         console.log('di', response.data);
  //       })
  //     }).finally(() => {
  //       setIsLoading(false);
  //     })
  //     // customStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMsInVzZXIiOiJhZG1pbiIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTczMDA0ODMwOH0.gM06z4FU7Ln8o-eC-PSH1DnhkPIAD143ueHnv6Q9W1I')
  //     // setIsLoading(false);
  //     // const initDataRaw = 'query_id=AAGqp0kzAAAAAKqnSTP_AspB&user=%7B%22id%22%3A860465066%2C%22first_name%22%3A%22%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22rarem0de%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1729966134&hash=4b09bfc9c4f48e2a9e68cf3feca20215764cffc4499ac9d1198b60c80614d634';
  //     // validateClient(initDataRaw);
  //     //for test local-backend:
  //     // const initDataRaw = 'query_id=AAGqp0kzAAAAAKqnSTP_AspB&user=%7B%22id%22%3A860465066%2C%22first_name%22%3A%22%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22rarem0de%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1729966134&hash=4b09bfc9c4f48e2a9e68cf3feca20215764cffc4499ac9d1198b60c80614d634';
  //     // validateClient(initDataRaw);
  //     //for test stage-backend:
  //     //customStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMsInVzZXIiOiJhZG1pbiIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTczMDEzNzM3NCwiZXhwIjoxNzYxNjczMzc0fQ.v4SfdHNtB7pxd3hY1WL446XizX6mIfzkQBWL4pOxIh4');
  //     //setIsClientValidated(true);
  //   }
  // }, []);

  // const validateClient = async (initDataRaw) => {

  //   axios.post(`${process.env['REACT_APP_BACKEND_URL']}/api/validation`, {}, {
  //     headers: {
  //       Authorization: `tma ${initDataRaw}`
  //     }
  //   })
  //     .then(response => {
  //       console.log('Ответ от сервера:', response.data);
  //       if (response.data.clientInfo) {
  //         customStorage.setItem('clientname', (response.data.clientInfo.first === null || response.data.clientInfo.last === null) ? "Empty name" : response.data.clientInfo.first + " " + response.data.clientInfo.last);

  //         customStorage.setItem('clientaddress', response.data.clientInfo.deliveryAddress.address ? response.data.clientInfo.deliveryAddress.address : "Empty address");
  //         customStorage.setItem('clientcity', response.data.clientInfo.deliveryAddress.city ? response.data.clientInfo.deliveryAddress.city : "Empty city");
  //         customStorage.setItem('clientcountry', response.data.clientInfo.country ? response.data.clientInfo.country : "Empty country");
  //         customStorage.setItem('clientpostcode', response.data.clientInfo.postcode ? response.data.clientInfo.postcode : "Empty postcode");
  //         customStorage.setItem('clientstate', response.data.clientInfo.state ? response.data.clientInfo.state : "Empty state");

  //       }
  //       if (response.data.accessToken) {
  //         customStorage.setItem('token', response.data.accessToken);
  //         setIsClientValidated(true);
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Произошла ошибка:', error);
  //     }).finally(() => {
  //       setIsLoading(false);
  //     });
  // }

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {/* {getTabContent(currentTab)} */}
      {apiError && <Snackbar
        description={apiError}
        style={{ zIndex: 1000 }}
        duration={3000}
        onClose={() => setApiError(null)}
      >
        {LocalizedErrorStrings.apiErrorTitle}
      </Snackbar>}
      {isLoading ? <div>loading</div> : <Outlet />}
      <Tabbar style={{
        paddingBottom: '34px',
        backgroundColor: (isLightTheme ? '#FFFFFF' : '#171717'),
        visibility: (isTabBarVisible ? 'visible' : 'hidden')
      }}
      >
        {tabs.map(({ id, text, iconClicked, iconNormal, to }) => {
          const isSelected = match?.pathnameBase === to
          return <Tabbar.Item
            key={id}
            text={text}
            selected={isSelected}
            onClick={() => navigate(to)}
            // onClick={() => setCurrentTab(id)}
            style={{ padding: '4px 0px', marginBottom: '2px', color: (isSelected ? '#007AFF' : '#8E8E93') }}
          >
            <div>

              {/* <Badge  //НЕ УДАЛЯТЬ, ВОЗМОЖНО В БУДУЩЕМ ПОНАДОБИТСЯ БЭЙДЖ КОЛИЧЕСТВА ОРДЕРОВ
                                style={{
                                position: "absolute",
                                top: 4,
                                marginLeft: "24px",
                                visibility: (OrdersCount > 0 && id === 2 && TabBarVisibility === true) ? "visible" : "hidden",
                                backgroundColor: "#007AFF",
                                }}
                                mode="primary"
                                type="number"
                            >
                                {OrdersCount}
                            </Badge> */}
              <img
                style={{ position: 'relative', top: 4 }}
                src={isSelected ? iconClicked : iconNormal}
                alt="tabbar-image"
              />

            </div>
          </Tabbar.Item>
        })
        }
      </Tabbar>
    </div >
  )
}

export default Default
