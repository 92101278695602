import { LocalizedErrorStrings, LocalizedStrings } from '../../../res/localization'
import {
  List,
  Input,
  Chip,
  FixedLayout,
  Button,
  Banner,
  Snackbar
} from '@telegram-apps/telegram-ui'
import React, { useState, useEffect, useContext } from 'react'
import { CatalogItem, CatalogTopBanner, HapticTouchBottomButton } from '../index'
import Logo from '../../../res/images/logo-2323.svg'
// import OrderPlaced from "./CatalogOrderPlaced.js";
// import CatalogOrderAddingYourOrder from "../CatalogOrderAdding/CatalogOrderAddingYourOrder/CatalogOrderAddingYourOrder.js";
// import CatalogOrderAddingYourOrderDeliveryDetails from "../CatalogOrderAdding/CatalogOrderAddingYourOrder/CatalogOrderAddingYourOrderDeliveryDetails/CatalogOrderAddingYourOrderDeliveryDetails.js";
// import customStorage from "../../../CustomStorage.js";
import { cross } from '../../../res/icons/index.js'
import { useCartStore } from '../../../store/cart.js'
import { useUserStore } from '../../../store/user.js'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import { getCategories as getCategoriesApi, getGoods as getGoodsApi } from '../../../api'
import { ThemeContext, TabBarHeight, ViewOrderBarHeight } from '../../../lib'

const MainPage = ({ _props }) => {
  const [FilterType, setFilterType] = useState([])
  const [SelectedFilter, setSelectedFilter] = useState(0)
  const [SearchText, setSearchText] = useState('')
  const [isBannerVisible, setIsBannerVisible] = useState(true)
  const cart = useCartStore((store) => store)
  const canGetProducts = useUserStore((store) => store.isValid)
  const navigate = useNavigate()
  const [apiError, setApiError] = useState(null)

  const [ProductsList, setProductsList] = useState([])
  const { isLightTheme } = useContext(ThemeContext)

  const filteredProducts = ProductsList.filter((item) =>
    item.caption.toLowerCase().includes(SearchText.toLowerCase()) &&
    (SelectedFilter === 0 || item.article.categoryId === SelectedFilter)
  )

  const getCategories = async () => {
    try {
      const response = await getCategoriesApi()
      fillCategories(response.data.rows)
    } catch (error) {
      setApiError(LocalizedErrorStrings.failedLoadCategories)
    }
  }

  const fillCategories = (data) => {
    const allCategory = [
      {
        id: 0,
        caption: LocalizedStrings.catalogFilterChipAll,
        description: 'All show'
      }
    ]
    setFilterType(allCategory)
    setFilterType((prevState) => [...prevState, ...data])
  }

  const getGoods = async () => {
    try {
      const response = await getGoodsApi()
      setProductsList(response.data.rows)
      if (response.data.count === 0) {
        setFilterType([])
      } else {
        setProductsList(response.data.rows)
      }
    } catch (error) {
      setApiError(LocalizedErrorStrings.failedLoadGoods)
    }
  }

  // const getCart = async () => {
  //     api.get(`${process.env['REACT_APP_BACKEND_URL']}/api/cart`, {
  //         headers: {
  //             Authorization: `JWT ${customStorage.getItem('token')}`
  //         }
  //     }).then(response => {
  //         const extractedCartData = response.data.compositionOrders.map(item => ({
  //             goodId: item.good.id,
  //             count: item.count
  //         }));
  //         console.log('Ответ от сервера: cart', extractedCartData);
  //         setCart(extractedCartData);
  //     })
  //     .catch(error => {
  //         console.error('Произошла ошибка:', error);
  //     });
  // }

  // const updateCart = async (goodId, count) => {
  //     let cart = {
  //         goodId: goodId,
  //         count: count
  //     }

  //     axios.put(`${process.env['REACT_APP_BACKEND_URL']}/api/cart/update`, cart, {
  //         headers: {
  //             Authorization: `JWT ${customStorage.getItem('token')}`
  //         }
  //     })
  //     .then(response => {})
  //     .catch(error => {
  //         console.error('Произошла ошибка:', error);
  //     });
  // }

  useEffect(() => {
    console.log('FilterType', FilterType)
  }, [FilterType])

  const putQuantityOfProduct = (itemId) => {
    // const item = Cart.find(cartItem => cartItem.goodId === itemId);
    // return item ? item.count : 0;
    // return cart.cart[itemId] || 0
    return cart.cart[itemId] || 0
  }

  // Функция для добавления товара в корзину
  const addItemToCart = async (item) => {
    cart.increment({ goodId: item.id }, setApiError)
    // setCart((prevCart) => {
    // const existingItem = prevCart.find(cartItem => cartItem.goodId === item.id);
    // updateCart(item.id, existingItem ? existingItem.count + 1 : 1);
    // if (existingItem) {
    //     return prevCart.map(cartItem =>
    //     cartItem.goodId === item.id
    //         ? { goodId: item.id, count: cartItem.count + 1 }
    //         : cartItem
    //     );
    // } else {
    //     return [...prevCart, { ...existingItem, goodId: item.id, count: 1 }];
    // }
    // });
  }

  // Функция для убавления товара в корзине
  const removeItemFromCart = (item) => {
    cart.decrement({ goodId: item.id }, setApiError)
    // setCart((prevCart) => {
    // const existingItem = prevCart.find(cartItem => cartItem.goodId === item.id);
    // updateCart(item.id, existingItem ? existingItem.count - 1 : 0);
    // if (existingItem.count === 1) {
    //     return prevCart.filter(cartItem => cartItem.goodId !== item.id);
    // } else {
    //     return prevCart.map(cartItem =>
    //     cartItem.goodId === item.id
    //         ? { ...cartItem.id, goodId: item.id, count: cartItem.count - 1 }
    //         : cartItem
    //     );
    // }
    // });
  }

  const goToViewOrder = () => {
    navigate('/vieworder')
    console.log('goToViewOrder')
  }

  useEffect(() => {
    if (canGetProducts) {
      getGoods()
      getCategories()
    }
  }, [canGetProducts])

  return (
    // TODO: - implement
    <div
      style={{
        minHeight: '100%',
        paddingTop: '16px',
        paddingBottom: '110px',
        backgroundColor: isLightTheme ? '#EFEFF4' : '#0F0F0F'
      }}
    >
      {apiError && <Snackbar
        description={apiError.message}
        style={{ zIndex: 1000 }}
        duration={3000}
        onClose={() => setApiError(null)}
      >
        {LocalizedErrorStrings.apiErrorTitle}
      </Snackbar>}
      <List className={styles.CatalogMain}>
        <CatalogTopBanner />
        <div className={styles.SearchBarContainer}>
          <h2 className={styles.SearchBarFooter}>{LocalizedStrings.searchBarHeader}</h2>
          <Input
            value={SearchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={ LocalizedStrings.catalogSearchBarPlaceholder }
            className={styles.SearchBarInput}
          />
        </div>
        <div className={styles.FilterCarousel}>
          {FilterType.length !== 0 &&
            ProductsList.length !== 0 &&
            FilterType.map((item) => (
              <Chip
                className={styles.FilterChip}
                mode={
                  SelectedFilter === item.id
                    ? isLightTheme
                      ? 'elevated'
                      : 'mono'
                    : ''
                }
                key={item.id}
                onClick={() => setSelectedFilter(item.id)}
              >
                <div className={styles.FilterChipText}>{item.caption}</div>
              </Chip>
            ))}
        </div>

        {filteredProducts.length > 0
          ? (
          <div className={styles.ProductsList}
          >
            {filteredProducts.map((item) => (
              <CatalogItem
                key={item.id}
                item={item}
                plusProductCount={addItemToCart}
                minusProductCount={removeItemFromCart}
                isLightTheme={isLightTheme}
                quantity={putQuantityOfProduct(item.id)}
              />
            ))}
          </div>
            )
          : (
              SearchText.length > 0 && (
            <div className={styles.ProductsNotFound}>
              {LocalizedStrings.productsNotFound}
            </div>
              )
            )}
      </List>

      {isBannerVisible && ( // отображаем баннер, только если isBannerVisible === true
        <Banner
          style={{
            margin: '8px 16px',
            zIndex: 0,
            backgroundColor: isLightTheme ? 'white' : 'rgba(49, 49, 49, 1)'
          }}
          before={<img src={Logo} className={styles.CatalogBottomBannerLogo} />}
          header={
            <div style={{ color: isLightTheme ? '#000' : '#fff' }}>
              {LocalizedStrings.catalogBottomBannerTitle}
            </div>
          }
          description={LocalizedStrings.catalogBottomBannerDescription}
          type="inline"
        >
          <React.Fragment key=".0">
            <Button
              size="s"
              className={styles.CatalogBottomBannerJoinButton}
              onClick={() => window.open('https://t.me/one2323xChkP', '_blank')}
            >
              {LocalizedStrings.catalogBottomBannerButton}
            </Button>
          </React.Fragment>
          <button
            onClick={() => setIsBannerVisible(false)}
            className={styles.CatalogBottomBannerCloseButton}
          >
            <img
              src={cross}
              className={styles.CatalogBottomBannerCloseButtonImage}
            />
          </button>
        </Banner>
      )}

      <FixedLayout
        style={{
          zIndex: 1,
          backdropFilter: 'blur(10px) saturate(120%)',
          visibility: Object.keys(cart.cart).length > 0 ? 'visible' : 'hidden',
          height: '110px'
        }}
      >
        <div className={styles.ViewOrderButtonWrapper}>
          <HapticTouchBottomButton
          onClick={goToViewOrder}
          normalStyle={styles.ViewOrderButton}
          pressedStyle={styles.ViewOrderButtonPressed}
          buttonText={LocalizedStrings.catalogViewOrder}
          />
        </div>
      </FixedLayout>
    </div>
  )
}

export default MainPage

// const Default = ({_props, tabBarVisibility, canGetProducts}) => {
//     console.log('catalog main')
//     const [currentTabPage, setCurrentTabPage] = useState(0); // 0 - main, 1 - Your order, 2 - delivery details, 3 - Order placed

//     const showYourOrderPage = () => setCurrentTabPage(1);
//     const hideYourOrderPage = () => setCurrentTabPage(0);

//     const showDeliveryDetailsPage = () => setCurrentTabPage(2);
//     const hideDeliveryDetailsPage = () => setCurrentTabPage(1);

//     const showOrderPlacedPage = () => setCurrentTabPage(3);
//     const hideOrderPlacedPage = () => setCurrentTabPage(0);

//     const [ExecutedOrderId, setExecutedOrderId] = useState(null);
//     const [ExecutedOrderSum, setExecutedOrderSum] = useState(null);

//     console.log("current tab page: ", currentTabPage);

//     switch (currentTabPage) {
//         case 0:
//           return <MainPage showYourOrderPage={showYourOrderPage} tabBarVisibility={tabBarVisibility} canGetProducts={canGetProducts} />;
//         case 1:
//           return <CatalogOrderAddingYourOrder hideDeliveryInfoPage={hideYourOrderPage} openDeliveryDetails={showDeliveryDetailsPage} />;
//         case 2:
//           return <CatalogOrderAddingYourOrderDeliveryDetails hideDeliveryDetailsPage={hideDeliveryDetailsPage} showOrderPlacedPage={showOrderPlacedPage} orderId={setExecutedOrderId} orderSum={setExecutedOrderSum}  />;
//         case 3:
//             return <OrderPlaced hideOrderPlacedPage={hideOrderPlacedPage} orderId={ExecutedOrderId} orderSum={ExecutedOrderSum} />
//         default:
//           return <MainPage showYourOrderPage={showYourOrderPage} tabBarVisibility={tabBarVisibility} />;
//       }
// }

// export default Default;
