import { Subheadline } from '@telegram-apps/telegram-ui'
import BannerBackground from '../../../../res/images/banner-bg.png'
import BannerLogo from '../../../../res/images/2323-time-logo.svg'
import styles from '../styles.module.css'
import { LocalizedStrings } from '../../../../res/localization'

export const CatalogTopBanner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${BannerBackground})`,
        backgroundColor: '#232220',
        backgroundSize: '380px 424px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center -77px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0px 16px 0',
        borderRadius: '16px',
        width: 'auto',
        padding: '16px 0'
      }}
    >
      <div className={styles.TopBannerTextWrapper}>
        <Subheadline
          className={styles.TopBannerTitle}
          level={1}
          weight={1}
        >
          {LocalizedStrings.welcomeToTheTeaShop}
        </Subheadline>
        <img
          className={styles.TopBannerLogo}
          src={BannerLogo}
        ></img>
      </div>
      <Subheadline
        className={styles.TopBannerSubtitle}
        level={2}
        weight={3}
      >
        {LocalizedStrings.exploreTeaAndCollabs}
      </Subheadline>
    </div>
  )
}
