import React from 'react'
import { FormInputWrapper, FormInputError } from './styled'
import { Input } from '../Input'

export const FormInput = React.forwardRef(({ error, onKeyDown, ...props }, ref) => {
  return (
    <FormInputWrapper>
      <Input {...props} ref={ref} onKeyDown={onKeyDown} />
      {error && <FormInputError>{error}</FormInputError>}
    </FormInputWrapper>
  )
})

FormInput.displayName = 'FormInput'
