import { Section, List, Cell, Button, Text } from '@telegram-apps/telegram-ui'
import { format, getMonth } from 'date-fns'
import { ReactComponent as InfoIcon } from '../../../../res/icons/info.svg'
import { orderBox, orderTea, orderCourier } from '../../../../res/icons'
import { OrderStatus, OrderItems } from '../../index'
import { getCurrencyRegularString, getPriceByCurrency, calculateOrderSumByCurrency } from '../../../../lib'
import styles from './styles.module.css'
import { LocalizedStrings } from '../../../../res/localization'

// TO DO replace in const resources
const months = [
  { id: 0, name: 'All' },
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' }
]

export const OrdersData = (props) => {
  const orders = props.data.filter(order => order.status !== 'open')

  const filterOrders = (orders, search) => {
    // Если строка поиска пуста, возвращаем весь список покупок
    if (!search || search.trim() === '') {
      return orders
    }

    const lowerCasedSearch = search.toLowerCase()

    return orders.filter(order =>
    // Поиск по id заказа
      String(order.id).includes(lowerCasedSearch) ||
        // Поиск по названию товара
        order.compositionOrders.some(composition =>
          composition.good.caption.toLowerCase().includes(lowerCasedSearch)
        )
    )
  }

  const filteredOrders = filterOrders(orders, props.search).filter( // Фильтрация заказов по месяцам
    order => props.filter === ''
      ? true
      : (getMonth(order.createdAt) + 1) === (months[props.filter]?.id ?? '')
  )

  if (filteredOrders.length === 0) {
    return (
      <div className={styles.OrdersNotFound}>
        {LocalizedStrings.ordersNotFound}
      </div>
    )
  }
  return (
    <List>
      {filteredOrders.map((order) => (
        <Section
          key={order.id}
        >
          <div>
            <Cell
              before={
                <div style={{ fontSize: '26px', lineHeight: '34px' }}>
                  📦
                </div>
              }
              description={'#' + order.id}
            >
              <span className={styles.OrderFromTitle}>
                {`${LocalizedStrings.orderFrom} ${format(order.createdAt, 'dd.MM.yyyy')}`}
              </span>
            </Cell>
            {/* <Cell
              before={
                <div>
                  <img
                    src={orderCourier}
                    alt="delivery"
                    width={27}
                    height={27}
                  />
                </div>
              }
              title={`Courier Delivery`}
              description={`Estimated delivery ${format(order.delivery.estimated_delivery_time, 'dd MMMM')}`}
            >
              <span style={{ fontSize: '16', fontWeight: '600' }}>
                {`Courier Delivery`}
              </span>
            </Cell> */}
            <div className={styles.OrderStatusWrapper}>
              <OrderStatus status={order.status} date={format(order.createdAt, 'dd.MM.yyyy')} />
            </div>
            <OrderItems data={order.compositionOrders} />
            <Cell
              after={getCurrencyRegularString(calculateOrderSumByCurrency(order.compositionOrders).toFixed(2))}
              className={styles.OrderDataTotalPriceWrapper}
            >
              <Text className={styles.OrderDataTotalPrice}>{LocalizedStrings.totalPrice}</Text>
            </Cell>
          </div>
        </Section>
      ))}
    </List>
  )
}
