import { List, Text, Button, Timeline, Title, LargeTitle, Headline, Subheadline } from '@telegram-apps/telegram-ui'
import { LocalizedStrings } from '../../../res/localization'
import { useState, useEffect, useContext } from 'react'
import customStorage from '../../../CustomStorage'
import { ThemeContext, TabBarHeight } from '../../../lib'
import { useLocation, useNavigate } from 'react-router-dom'
import { startValidateTransaction } from '../../../api'

export const CatalogOrderPlaced = ({ _props }) => {
  const [OrderProgress, setOrderProgress] = useState(1)
  const [OrderPlacedDate, setOrderPlacedDate] = useState('Yesterday')
  const [TransitEstimatedPeriod, setTransitEstimatedPeriod] = useState('1-3 days')
  const [DeliverDateExpected, setDeliverDateExpected] = useState('January 4th')

  const { isLightTheme } = useContext(ThemeContext)
  const navigate = useNavigate()
  const location = useLocation()

  const preparedContactMessage = LocalizedStrings.orderContactQuestionForm + (location?.state ? location?.state?.orderId : 'TEST_ORDER_ID')
  const encodedContactMessage = encodeURIComponent(preparedContactMessage)

  const [OrderNameEnglish, setOrderNameEnglish] = useState('Amituofo')
  // const [OrderNameChinese, setOrderNameChinese] = useState('阿弥陀佛')

  const validateTransaction = async () => {
    const sum = parseFloat(location.state.orderSum.toFixed(2))
    const body = { orderId: location.state.orderId, orderSum: sum }
    setOrderNameEnglish(LocalizedStrings.orderNumber + location.state.orderId)
    console.log('start validate transaction with body: ', body)

    try {
      startValidateTransaction(body)
    } catch (error) {
      console.error('Произошла ошибка валидации платежа:', error)
    }
  }

  useEffect(() => {
    if (location.state === null) {
      navigate('/')
      return
    }
    if (location.state.orderId !== null && location.state.orderSum !== null) {
      validateTransaction()
    }
  }, [])

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    navigate('/')
  })

  const closeOrderPlacedScreen = () => {
    navigate('/')
    BackButton.hide()
  }

  return (
      <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: (isLightTheme ? '#EFEFF4' : '#0F0F0F'),
        overflowX: 'hidden' // скрываем горизонтальный скролл
      }}
    >
      <List
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '5vh',
          justifyContent: 'center'
        }}
      >
          {/* Картинка чая */}
          <Text // uncomment code below if need to use Image instead of Text
            // src="https://avatars.mds.yandex.net/i?id=ed6f335263878142d4ecdc7d48e5b609_l-5234303-images-thumbs&n=13"
            style={{
              // width: "84px", height: "108px",
              alignSelf: 'center',
              fontSize: '83px',
              lineHeight: '108px',
              backgroundColor: 'transparent'
            }}
          >
            🍵
          </Text>

          {/* Название заказа и статус */}
          <LargeTitle
            align="center"
            weight="1"
          >
            {OrderNameEnglish}
          </LargeTitle>
          {/* <LargeTitle
          style={{ marginTop: '-8px' }}
            align="center"
            weight="1"
          >
            {OrderNameChinese}
          </LargeTitle> */}
          <Subheadline
          style={{ marginTop: '-4px', fontSize: '17px', color: (isLightTheme ? 'rgba(112, 117, 121, 1)' : 'rgba(170, 170, 170, 1)') }}
            align="center"
            weight="3"
            level="1"
          >
            {LocalizedStrings.orderPlaced}
          </Subheadline>

          {/* <Timeline style={{ paddingLeft: '20px', marginTop: '-20px' }}> Return opacity when need to show order delivery timeline
            <Timeline.Item
              header="Order placed"
              mode={OrderProgress <= 0 ? '' : 'active'}
            >
              {OrderPlacedDate}
            </Timeline.Item>
            <Timeline.Item
              style={{ accentColor: 'red' }}
              header="In Transit"
              mode={OrderProgress <= 1 ? '' : 'active'}
            >
              Estimated time: {TransitEstimatedPeriod}
            </Timeline.Item>
            <Timeline.Item
              header="Delivered"
              mode={OrderProgress <= 2 ? '' : 'active'}
            >
              Expected {DeliverDateExpected}
            </Timeline.Item>
          </Timeline> */}

          {/* Кнопки "Contact Support" и "Back to Catalog" */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: TabBarHeight,
              margin: '0 16px',
              marginTop: '12px'
            }}
          >
            <Button
              stretched={true}
              style={{ marginRight: '12px', background: '#8E8E93' }}
              // eslint-disable-next-line react/no-children-prop
              children={LocalizedStrings.contactSupport}
              onClick={() => window.open(`https://t.me/TeaStore2323_bot?text=${encodedContactMessage}`, '_blank')}
            />
            <Button
              stretched={true}
              style={{ color: '#fff', background: '#007AFF' }}
              // eslint-disable-next-line react/no-children-prop
              children={LocalizedStrings.backToCatalog}
              onClick={closeOrderPlacedScreen}
            />
          </div>
        </List>
      </div>
  )
}
