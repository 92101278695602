import { useState, useRef, useEffect, useContext } from 'react'
import { Popper, List, Cell, Subheadline } from '@telegram-apps/telegram-ui'
import { settingsChevronVertical, settingsPopupAction } from '../../../../../../res/icons'
import styles from '../styles.module.css'
import { ThemeContext } from '../../../../../../lib'
import { LocalizedStrings } from '../../../../../../res/localization'

export const CatalogPaymentMethodPopUp = ({ selectedMethod, setSelectedMethod, paymentMethods, currentActiveId, setCurrentActiveId }) => {
  const targetRef = useRef(null)
  const popperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isLightTheme } = useContext(ThemeContext)

  const handleClickCapture = () => {
    setIsOpen((prev) => !prev)
  }

  const handleSelect = (id) => {
    setCurrentActiveId(id)
    setSelectedMethod(id)
  }

  const handleClickOutside = (event) => {
    if (
      targetRef.current &&
      !targetRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  const getPaymentLabel = () => {
    return paymentMethods.find(method => method.id === selectedMethod)
  }

  return (
    <div>
      <div onClickCapture={handleClickCapture} ref={targetRef} className={styles.paymentMethodContainer}>
        <span className={styles.paymentMethodText}>{LocalizedStrings.paymentMethod}</span>
        <div className={styles.methodsRight}>
          <Subheadline weight='1' level='2' >{ getPaymentLabel().label ? getPaymentLabel().label : 'Select payment method' }</Subheadline>
          <img src={settingsChevronVertical} alt="Chevron" className={styles.chevronIcon} />
        </div>
      </div>
      {isOpen && (
        <Popper
          className={styles.Popper}
          arrowProps={{
            style: {
              display: 'none'
            }
          }}
          targetRef={targetRef}
          ref={popperRef}
          placement="bottom-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -15] // Raised the pop-up by 15 pixels
              }
            }
          ]}
        >
          <div className={isLightTheme ? styles.PaymentMethodsWrapperLight : styles.PaymentMethodsWrapperDark}>
            <List className={styles.PaymentMethodList}>
              {paymentMethods.map((method, index) => (
                <>
                  <Cell
                    className={styles.elemStyle}
                    after={method.id === currentActiveId ? <img src={settingsPopupAction} alt="Action" /> : null}
                    onClick={() => handleSelect(method.id)}
                    description={method.description}
                  >
                    {method.label}
                  </Cell>
                  {index < paymentMethods.length - 1 && (
                    <hr className={isLightTheme ? styles.PaymentMethodDividerLight : styles.PaymentMethodDividerDark}/>
                  )}
                </>
              ))}
            </List>
          </div>
        </Popper>

      )}
    </div>
  )
}
