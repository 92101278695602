import { useState, useEffect } from 'react'
import { Skeleton, List, Snackbar } from '@telegram-apps/telegram-ui'
import styles from './styles.module.css'
import { OrdersData } from '../../index'
import { getPersonalOrders } from '../../../../api'
import { LocalizedErrorStrings } from '../../../../res/localization'

const Preloader = () => {
  return <List>
   <Skeleton
      visible
      className={styles.OrderSkeleton}
    >
      <div className={styles.OrderStub}></div>
    </Skeleton>
    <Skeleton
      visible
      className={styles.OrderSkeleton}
    >
     <div className={styles.OrderStub}></div>
    </Skeleton>
  </List>
}

export const OrderList = (props) => {
  const { monthsWithOrders } = props
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [apiError, setApiError] = useState(null)

  useEffect(() => {
    getPersonalOrders().then((res) => {
      setIsLoading(false)
      setData(res.data)
      const months = Array.from(
        new Set(
          res.data.map(order => new Date(order.createdAt).getMonth() + 1) // Добавляем 1, так как getMonth возвращает месяцы от 0 до 11
        )
      )
      monthsWithOrders(months)
    }).catch((err) => {
      console.warn('error when getting orders', err)
      setApiError({ message: LocalizedErrorStrings.failedLoadPersonalOrders })
      setIsLoading(false)
    })
  }, [])

  const addAdditionalPrices = (cart) => { // remove that function after server response include purchaseRub/purchaseNanoTon values
    return cart.map((carts) => ({
      ...carts,
      compositionOrders: carts.compositionOrders.map((order) => ({
        ...order,
        good: {
          ...order.good,
          price: {
            ...order.good.price,
            purchaseRub: 1,
            purchaseNanoTon: 10000000 * order.good.id
          }
        }
      }))
    })
    )
  }

  return (<div
    className={styles.OrderList}
  >
      {apiError && <Snackbar
        description={apiError.message}
        style={{ zIndex: 1000 }}
        duration={3000}
        onClose={() => setApiError(null)}
      >
        {LocalizedErrorStrings.apiErrorTitle}
      </Snackbar>}
    {isLoading
      ? <Preloader />
      : <OrdersData
      data={data}
      filter={props?.filter || ''}
      search={props.search || ''}
      />
    }
  </div>)
}
