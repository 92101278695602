import customStorage from '../CustomStorage'

export const Currencies = {
  TON: 'ton',
  USD: 'usd',
  RUB: 'rub'
}

export const nanotonToTon = (value) => {
  return value / 1000000000
}

export const tonToNanoton = (value) => {
  return value * 1000000000
}

export const getPriceByCurrency = (price) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return nanotonToTon(price.purchaseNanoTon).toFixed(2)
    case Currencies.USD:
      return parseFloat(price.purchase).toFixed(2)
    case Currencies.RUB:
      return parseFloat(price.purchaseRub).toFixed(2)
  }
}

export const getPriceByCurrencyString = (price) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return nanotonToTon(price.purchaseNanoTon).toFixed(2) + ' TON'
    case Currencies.USD:
      return '$' + parseFloat(price.purchase).toFixed(2)
    case Currencies.RUB:
      return parseFloat(price.purchaseRub).toFixed(2) + ' ₽'
  }
}

export const getCurrencyRegularString = (value) => {
  const currency = customStorage.getItem('currency')
  switch (currency) {
    case Currencies.TON:
      return value + ' TON'
    case Currencies.USD:
      return '$' + value
    case Currencies.RUB:
      return value + ' ₽'
  }
}
