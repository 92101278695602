import { useState, useRef, useContext } from 'react'
import { Popper, List } from '@telegram-apps/telegram-ui'
import { settingsArrow, walletCopy, walletDisconnect } from '../../../../../../res/icons'
import styles from '../styles.module.css'
import { ThemeContext } from '../../../../../../lib'
import { LocalizedStrings } from '../../../../../../res/localization'
import TruncatedString from './TruncatedString'

export const ConnectedWalletPopUp = (props) => {
  const { walletAddress, onDisconnect } = props
  const targetRef = useRef(null)
  const popperRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const { isLightTheme } = useContext(ThemeContext)

  const handleClickCapture = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event) => {
    if (
      targetRef.current &&
      !targetRef.current.contains(event.target) &&
      popperRef.current &&
      !popperRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  const onCopyRequest = () => {
    navigator.clipboard.writeText(walletAddress)
    setIsOpen(false)
  }

  const onDisconnectRequest = () => {
    onDisconnect()
    setIsOpen(false)
  }

  return (
    <div>
      <div onClickCapture={handleClickCapture} ref={targetRef} >
        {/* <span className={styles.paymentMethodText}>{LocalizedStrings.paymentMethod}</span> */}
        <div className={styles.methodsRight}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px', paddingBottom: '4px', width: '100%' }}>
          <span style={{ margin: '12px 0px 12px 12px', color: '#707579', fontSize: '17px' }}>
            Кошелек
            </span>
            <div style={{ alignItems: 'center', display: 'flex', marginRight: '12px' }}>
            <TruncatedString style={{ marginRight: '8px' }} str={walletAddress} startChars={4} endChars={4} />
            <img src={settingsArrow} alt="Arrow" />
            </div>
        </div>
        </div>
      </div>
      {isOpen && (
        <Popper
          className={styles.PopperConnectedWallet}
          arrowProps={{
            style: {
              display: 'none'
            }
          }}
          targetRef={targetRef}
          ref={popperRef}
          placement="bottom-end"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -15] // Raised the pop-up by 15 pixels
              }
            }
          ]}
        >
          <div className={isLightTheme ? styles.PaymentMethodsWrapperLight : styles.PaymentMethodsWrapperDark}>
            <List className={styles.ConnectedWalletList}>
                <div onClick={onCopyRequest} className={styles.ConnectedWalletCell}>
                    <div>{LocalizedStrings.copyWalletAddress}</div>
                    <img className={styles.ConnectedWalletCellIcon} src={walletCopy}></img>
                </div>

                <div className={styles.ConnectedWalletCellDivider}></div>

                <div onClick={onDisconnectRequest} className={styles.ConnectedWalletCell}>
                    <div>{LocalizedStrings.disconnectWallet}</div>
                    <img className={styles.ConnectedWalletCellIcon} src={walletDisconnect}></img>
                </div>
            </List>
          </div>
        </Popper>

      )}
    </div>
  )
}
