import React from 'react'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { routes } from './routes'
import { ThemeProvider, getFrontUrl } from './lib'

const router = createBrowserRouter(routes)

function App () {
  return (
    <TonConnectUIProvider
      manifestUrl={getFrontUrl() + '/tonconnect-manifest.json'}
      actionsConfiguration={{
        twaReturnUrl: 'https://t.me/TeaStore2323_bot/teahouse2323store'
      }}
    >
      <ThemeProvider>
      <RouterProvider router={router} />
      </ThemeProvider>
    </TonConnectUIProvider>
  )
}

export default App
