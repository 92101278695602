import React, { useState, useEffect } from 'react'
import { Modal, Button, Timeline, Placeholder } from '@telegram-apps/telegram-ui'
import { cross, infoIcon } from '../../../../res/icons'
import { LocalizedStrings } from '../../../../res/localization'
const buttonStyle = {
  backgroundColor: 'rgba(0, 122, 255, 1)',
  color: 'white',
  marginLeft: '60px',
  padding: '8px 12px',
  borderRadius: '20px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
  fontWeight: '600',
  fontSize: '15px',
  width: 'auto',
  height: '100%',
  minWidth: 168
}

const statusesOrder = ['paid', 'shipped', 'delivered']

const getProgress = (status) => {
  return statusesOrder.indexOf(status)
}

const OrderStatusModal = (props) => {
  const { open, onOpen, onClose, date, status } = props
  // const isDelivered = statusesOrder.indexOf(status) === 2

  const getLocalizedOrderStatus = (status) => {
    if (status === 'paid') {
      return LocalizedStrings.orderStatusPaid
    } else if (status === 'shipped') {
      return LocalizedStrings.orderStatusShipped
    } else if (status === 'delivered') {
      return LocalizedStrings.orderStatusDelivered
    }
  }

  return (
    <Modal
      open={open}
      onOpenChange={(open) => {
        open ? onOpen() : onClose()
      }}
      trigger={
        <div style={{ height: '36px' }}>
          <Button
            size="m"
            style={{ ...buttonStyle, pointerEvents: 'none' }}
             onClick={onOpen}
            // disabled
          >
            {getLocalizedOrderStatus(status)}
            {/* {isDelivered ? "Order Delivered" : "Order Processed"} */}
            <img src={infoIcon} style={{ height: '16px', margin: '0 0 -3px 6px' }} />

          </Button>
        </div>
      }
    >
      <button
        onClick={onClose}
        style={{
          padding: '0',
          width: '28px',
          height: '28px',
          borderRadius: '90px',
          position: 'absolute',
          top: '16px',
          right: '16px',
          border: 'none',
          fontSize: '12px',
          cursor: 'pointer',
          color: 'rgba(0, 0, 0, 0.8)',
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }}
      >
        <img src={cross} />
      </button>

      <Placeholder style={{ padding: '6px' }}>
        <Modal.Header >
          {LocalizedStrings.orderFrom}{date}
        </Modal.Header>
      </Placeholder>
      <div style={{
        padding: '27px 0px 0',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        margin: ''

      }}>

        <Timeline style={{ padding: '0 44px 36px' }}>
          <Timeline.Item
          style={{ height: '50px' }}
            header={LocalizedStrings.orderStatusPaidTimeline}
            mode={getProgress(status) >= 0 ? 'active' : ''}
          >
            {/* Yesterday */}
          </Timeline.Item>
          <Timeline.Item
            style={{ height: '50px' }}
            header={LocalizedStrings.orderStatusShippedTimeline}
            mode={getProgress(status) >= 1 ? 'active' : ''}
          >
            {/* Estimated time: 1-3 days */}
          </Timeline.Item>
          <Timeline.Item
            style={{ height: '50px' }}
            header={LocalizedStrings.orderStatusDeliveredTimeline}
            mode={getProgress(status) >= 2 ? 'active' : ''}
          >
            {/* Expected January 4th */}
          </Timeline.Item>
        </Timeline>
        <div style={{ padding: '0 32px 34px' }}>
          <Button
            onClick={onClose}
            style={{
              width: '100%',
              height: '50px',
              padding: '15px 32px',
              backgroundColor: 'rgba(0, 122, 255, 1)',
              color: 'white',
              borderRadius: '10px',
              border: 'none'

            }}
          >
            <span
            style={{ fontSize: '17px', fontWeight: '600' }}
            onClick={() => window.open('https://t.me/one2323xChkP', '_blank')}
            >{LocalizedStrings.contactSupport}</span>
          </Button>

        </div>

      </div>
    </Modal>
  )
}

export const OrderStatus = (props) => {
  const { date, status } = props
  const [showOrderStatus, setShowOrderStatus] = useState(false)

  const closeOrderStatus = () => {
    setShowOrderStatus(false)
  }

  const openOrderStatus = () => {
    setShowOrderStatus(true)
  }

  if (status === 'cancelled') {
    return <Button size='m'
      style={{
        ...buttonStyle,
        backgroundColor: 'rgba(255, 0, 0, 1)',
        pointerEvents: 'none'
      }}
      onClick={() => {}}
      // disabled
    >
      {LocalizedStrings.orderStatusCancelled}
    </Button>
  } else if (status === 'pending') {
    return <Button
      size='m'
      style={{
        ...buttonStyle,
        backgroundColor: 'rgba(0, 122, 255, 1)',
        pointerEvents: 'none'
      }}
        onClick={() => {}}
      // disabled
    >
      {LocalizedStrings.orderStatusPending}
    </Button>
  }

  return (
    <OrderStatusModal
      onClose={closeOrderStatus}
      onOpen={openOrderStatus}
      date={date}
      status={status}
      open={showOrderStatus}
    />
  )
}
