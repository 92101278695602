import { Section, List, Snackbar } from '@telegram-apps/telegram-ui'
import { LocalizedErrorStrings, LocalizedStrings } from '../../../res/localization'
import React, { useState, useEffect, useContext } from 'react'
import customStorage from '../../../CustomStorage'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { receiverEditSchema, deliveryInfoEditSchema, registerField } from '../../../model'
import { FormInput } from '../../Common'
import { useUserStore } from '../../../store'
import styles from './styles.module.css'
import { ThemeContext } from '../../../lib'
import { useNavigate } from 'react-router-dom'

export const SettingsDeliveryInfo = () => {
  // const { hideDeliveryInfoPage } = props
  // TODO: - implement content
  const { isLightTheme } = useContext(ThemeContext)
  const { deliveryInfo, receiver } = useUserStore(store => store.user)
  const updateDeliveryInfo = useUserStore(store => store.update)
  const [apiError, setApiError] = useState(null)
  const navigate = useNavigate()

  const deliveryInfoForm = useForm({

    defaultValues: {
      address: customStorage.getItem('clientaddress') !== 'Empty address' ? customStorage.getItem('clientaddress') : '',
      country: customStorage.getItem('clientcountry') !== 'Empty country' ? customStorage.getItem('clientcountry') : '',
      city: customStorage.getItem('clientcity') !== 'Empty city' ? customStorage.getItem('clientcity') : '',
      postcode: customStorage.getItem('clientpostcode') !== 'Empty postcode' ? customStorage.getItem('clientpostcode') : '',
      name: customStorage.getItem('clientname') !== 'Empty name' ? customStorage.getItem('clientname') : '',
      phone: customStorage.getItem('clientphone') !== 'Empty phone' ? customStorage.getItem('clientphone') : '',
      email: customStorage.getItem('clientemail') !== 'Empty email' ? customStorage.getItem('clientemail') : ''
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: zodResolver(deliveryInfoEditSchema.merge(receiverEditSchema)),
    shouldFocusError: false
  })
  const { setValue } = deliveryInfoForm

  useEffect(() => {
    if (deliveryInfo) {
      console.log('deliveryInfo', deliveryInfo, receiver)
      setValue('address', deliveryInfo.address || '')
      setValue('country', deliveryInfo.country || '')
      setValue('city', deliveryInfo.city || '')
      setValue('postcode', deliveryInfo.postcode || '')
      setValue('state', deliveryInfo.state || '')
    }
    if (receiver) {
      setValue('name', receiver.name || customStorage.getItem('clientname'))
      setValue('phone', receiver.phone || '')
      setValue('email', receiver.email || '')
    }
  }, [deliveryInfo, receiver])

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    navigate('/settings')
    // hideDeliveryInfoPage()
  })

  const onDeliveryInfoSubmit = (data, ev) => {
    console.log('onDeliveryInfoSubmit', data)
    updateDeliveryInfo(data, setApiError)
  }

  const handleDeliveryInfoSubmit = deliveryInfoForm.handleSubmit(onDeliveryInfoSubmit)

  return (
    <div className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}>
      {apiError && <Snackbar
        description={apiError.message}
        style={{ zIndex: 1000 }}
        duration={3000}
        onClose={() => setApiError(null)}
      >
        {LocalizedErrorStrings.apiErrorTitle}
      </Snackbar>}
      <List className={styles.MainContentContainer}>
        <form onSubmit={handleDeliveryInfoSubmit}>
        <Section header = {<h2 className={styles.SectionHeader}>{LocalizedStrings.deliveryInformationSectionHeader}</h2>}>
            <FormInput
              placeholder={LocalizedStrings.addressInputPlaceholder}
              value={deliveryInfoForm.getValues('address')}
              {...registerField(deliveryInfoForm, 'address')}
              error={deliveryInfoForm.formState.errors.address?.message || ''}
            />
            <FormInput
              placeholder={LocalizedStrings.countryInputPlaceholder}
              value={deliveryInfoForm.getValues('country')}
              {...registerField(deliveryInfoForm, 'country')}
              error={deliveryInfoForm.formState.errors.country?.message || ''}
            />
            <FormInput
              placeholder={LocalizedStrings.cityInputPlaceholder}
              value={deliveryInfoForm.getValues('city')}
              {...registerField(deliveryInfoForm, 'city')}
              error={deliveryInfoForm.formState.errors.city?.message || ''}
            />
            <FormInput
              placeholder={LocalizedStrings.postcodeInputPlaceholder}
              value={deliveryInfoForm.getValues('postcode')}
              type="number"
              {...registerField(deliveryInfoForm, 'postcode')}
              error={deliveryInfoForm.formState.errors.postcode?.message || ''}
            />
          </Section>
        {/* </form>

        <form
          onSubmit={handleSubmit}
          style={{ marginTop: '-12dpx' }}
        > */}
        <Section header = {<h2 className={styles.SectionHeader}>{LocalizedStrings.receiverSectionHeader}</h2>}>
        <FormInput
              placeholder={LocalizedStrings.nameInputPlaceholder}
              value={deliveryInfoForm.getValues('name')}
              {...registerField(deliveryInfoForm, 'name')}
              error={deliveryInfoForm.formState.errors.name?.message || ''}
            />
            <FormInput
              placeholder={LocalizedStrings.phoneNumberInputPlaceholder}
              value={deliveryInfoForm.getValues('phone')}
              type="phone"
              {...registerField(deliveryInfoForm, 'phone')}
              error={deliveryInfoForm.formState.errors.phone?.message || ''}
            />
            <FormInput
              placeholder={LocalizedStrings.emailInputPlaceholder}
              value={deliveryInfoForm.getValues('email')}
              type="email"
              {...registerField(deliveryInfoForm, 'email')}
              error={deliveryInfoForm.formState.errors.email?.message || ''}
            />
          </Section>
        </form>
      </List>
    </div >
  )
}
