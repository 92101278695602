import { create } from 'zustand'
import { userApi } from '../api'
import customStorage from '../CustomStorage'
import { retrieveLaunchParams } from '@telegram-apps/sdk'
import { isProdBuild } from '../lib'
import { LocalizedErrorStrings } from '../res/localization'

const userDefault = {
  receiver: {
    name: '',
    phone: '',
    email: ''
  },
  deliveryInfo: {
    address: '',
    city: '',
    postcode: '',
    state: '',
    country: ''
  }
}
function createUserSlice (set, get) {
  return {
    isLoading: true,
    isValid: false,
    user: { ...userDefault },
    update: async (data, onError) => {
      try {
        const response = await userApi.updateDeliveryAddress(data)
        const deliveryInfo = {
          address: response.data.address || '',
          city: response.data.city || '',
          postcode: response.data.postcode || '',
          state: response.data.state || '',
          country: response.data.country || ''
        }
        const receiver = {
          name: response.data.name || '',
          phone: response.data.phone || '',
          email: response.data.email || ''
        }
        set({ user: { receiver, deliveryInfo } })
      } catch (error) {
        onError?.(Error(LocalizedErrorStrings.failedUpdatedDeliveryInfo))
      }
    },
    /**
     * Initializes the user state with data from the server.
     * If the server returns an error, calls onError with the error.
     * @param {(error: Error) => void} onError - The error handler, optional.
     */
    init: async (onError) => {
      try {
        if (isProdBuild()) {
          const { initDataRaw } = retrieveLaunchParams()
          const response = await userApi.validateUser(initDataRaw)
          if (!response.data.accessToken) {
            throw new Error('User validation failed')
          } else {
            customStorage.setItem('token', response.data.accessToken)
            const user = {
              receiver: {
                name: formatUserName(response.data.clientInfo.first, response.data.clientInfo.last),
                phone: response.data.clientInfo.phone,
                email: response.data.clientInfo.email || ''
              },
              deliveryInfo: {
                address: response.data.clientInfo.deliveryAddress.address || '',
                city: response.data.clientInfo.deliveryAddress.city || '',
                postcode: response.data.clientInfo.deliveryAddress.postcode || '',
                state: response.data.clientInfo.deliveryAddress.state || '',
                country: response.data.clientInfo.deliveryAddress.country || ''
              }
            }
            set({
              isValid: true,
              isLoading: false,
              user
            })
          }
        } else {
          const response = await userApi.loginTest()
          if (response.data.accessToken) {
            customStorage.setItem('token', response.data.accessToken)
            set({ isValid: true })
          } else {
            throw new Error('Test user login failed')
          }
          const userResponse = await userApi.getDeliveryAddress().catch(error => { error.message.toString() })
          if (!userResponse) {
            throw new Error('Failed to load saved delivery information')
          } else {
            const deliveryInfo = {
              address: userResponse.data?.address || '',
              city: userResponse.data?.city || '',
              postcode: userResponse?.data.postcode || '',
              state: userResponse?.data.state || '',
              country: userResponse?.data.country || ''
            }
            const receiver = {
              name: response.data?.name || '',
              phone: userResponse.data?.phone || '',
              email: userResponse.data?.email || ''
            }
            set({
              isValid: true,
              isLoading: false,
              user: {
                receiver,
                deliveryInfo
              }
            })
          // console.log('i', get().user)
          }
        }
      } catch (error) {
        onError?.(error)
      } finally {
        set({ isLoading: false })
      }
      // replace user init from RootPage
    },
    reset: () => set({
      user: { ...userDefault },
      isLoading: true,
      isValid: false
    })
  }
}

export const useUserStore = create((set, get) => createUserSlice(set, get))

function formatUserName (firstName, lastName) {
  if (!firstName || !lastName) {
    return ''
  }
  return `${firstName} ${lastName}`.trim()
}
