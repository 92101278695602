import { getEnv } from './EnvironmentHelper'

export function getImageUrl (imageName) {
  return getEnv().REACT_APP_BACKEND_URL + '/api/images?imageName=' + imageName
}

export function getApiUrl () {
  return getEnv().REACT_APP_BACKEND_URL
}

export function getFrontUrl () {
  return getEnv().REACT_APP_FRONTEND_URL
}
