import { cartApi } from '../api'
import { create } from 'zustand'
import { LocalizedErrorStrings } from '../res/localization'

export function createCartSlice (set, get) {
  return {
    cart: {},
    order: [],
    resetCart: () => set(() => ({ cart: {}, orders: [] })),
    initCart: async (onError) => {
      try {
        const cart = {}
        const response = await cartApi.initCart()
        if (!response) {
          throw new Error('Cart initialization failed')
        } else {
          const order = response.data.compositionOrders
          const orderId = response.data.id
          const comment = response.data.description
          order.forEach(item => {
            const key = item.good.id
            cart[key] = item.count
          })
          set({ orderId, cart, order, comment })
        }
      } catch (error) {
        error.message = LocalizedErrorStrings.failedLoadCart
        onError?.(error)
      }
    },
    decrement: async ({ goodId }, onError) => {
      try {
        const cart = { ...get().cart }
        if (cart[goodId] > 1) {
          cart[goodId] = cart[goodId] - 1
        } else {
          delete cart[goodId]
        }
        const response = await cartApi.updateCart({ goodId, count: cart[goodId] || 0 })
        set({ cart, order: response?.data?.compositionOrders || [] })
      } catch (error) {
        error.message = LocalizedErrorStrings.failedUpdateCart
        onError?.(error)
      }
    },
    increment: async ({ goodId }, onError) => {
      try {
        let cart = { ...get().cart }
        cart = { ...cart, [goodId]: cart[goodId] ? cart[goodId] + 1 : 1 }
        const response = await cartApi.updateCart({ goodId, count: cart[goodId] })
        const order = response?.data?.compositionOrders || []
        console.log('cart inc', cart, order)
        set({ cart, order })
      } catch (error) {
        error.message = LocalizedErrorStrings.failedUpdateCart
        onError?.(error)
      }
    },
    removeGood: async ({ goodId }, onError) => {
      try {
        const cart = { ...get().cart }
        delete cart[goodId]
        const response = await cartApi.updateCart({ goodId, count: 0 })
        const order = response?.data?.compositionOrders || []
        set({ cart, order })
      } catch (error) {
        error.message = LocalizedErrorStrings.failedUpdateCart
        onError?.(error)
      }
    },
    selectCount: async ({ goodId, count }, onError) => {
      try {
        const cart = { ...get().cart }
        cart[goodId] = count
        const response = await cartApi.updateCart({ goodId, count })
        const order = response?.data?.compositionOrders || []
        set({ cart, order })
      } catch (error) {
        error.message = LocalizedErrorStrings.failedUpdateCart
        onError?.(error)
      }
    },
    updateComment: async ({ comment }, onError) => {
      try {
        const commentString = comment || ''
        const response = await cartApi.updateCart({ description: commentString })
        const order = response?.data?.compositionOrders || []
        set({ order, comment })
      } catch (error) {
        error.message = LocalizedErrorStrings.failedUpdateCart
        onError?.(error)
      }
    }
  }
}

export const useCartStore = create((get, set) => createCartSlice(get, set))
