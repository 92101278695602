import RootPage from '../components/Root/RootPage'
import CatalogMain from '../components/Catalog/Main/CatalogMain'
import OrdersMain from '../components/Orders/Main/OrdersMain'
import { Settings, SettingsDeliveryInfo } from '../components/Settings'
import { CatalogOrderAddingYourOrder, CatalogOrderAddingYourOrderDeliveryDetails, CatalogOrderPlaced } from '../components/Catalog/index'
export const routes = [
  {
    path: '/',
    element: <RootPage />,
    children: [
      {
        path: '/',
        children: [
          {
            index: true,
            path: '/',
            element: <CatalogMain />
          },
          {
            path: '/vieworder',
            element: <CatalogOrderAddingYourOrder />
          },
          {
            path: '/orderdetails',
            element: <CatalogOrderAddingYourOrderDeliveryDetails />
          },
          {
            path: '/orderplaced',
            element: <CatalogOrderPlaced />
          }
        ]
      },
      {
        path: '/orders',
        element: <OrdersMain />
      },
      {
        path: '/settings',
        children: [
          {
            path: '',
            element: <Settings />
          },
          {
            path: 'delivery-info',
            element: <SettingsDeliveryInfo />
          }
        ]
      }
    ]
  }
]
