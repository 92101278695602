import axios from 'axios'
import { api } from '.'
import { isProdBuild, getApiUrl } from '../lib'

const userValidateApi = axios.create({
  baseURL: getApiUrl()
})

function validateUser (initDataRaw) {
  return userValidateApi.post('/api/validation', {}, {
    headers: {
      Authorization: `tma ${initDataRaw}`
    }
  })
}

export const userApi = {
  api,
  validateUser,
  getDeliveryAddress: () => api.get('/api/delivery-address'),
  updateDeliveryAddress: (payload) => api.put('/api/delivery-address', payload),
  loginTest: isProdBuild()
    ? () => Promise.reject(Error('login test unavailable'))
    : () => userValidateApi.post('/api/auth', {
        login: 'admin',
        password: '92668751'
      })
}
