export const LocalizedRussianStrings = {
  deliveryInformation: 'Адрес доставки',
  catalog: 'Каталог',
  orders: 'Заказы',
  settings: 'Настройки',
  search: 'Поиск',
  paymentMethod: 'Способ оплаты',
  support: 'Поддержка',
  privacyPolicy: 'Политика конфиденциальности',
  termsOfUsage: 'Правила использования',
  teaShop: 'Tea Shop',
  back: 'Назад',
  orderPlaced: 'Спасибо! Ваш заказ сейчас обрабатывается, наш менеджер скоро свяжется с Вами!',
  contactSupport: 'Поддержка',
  backToCatalog: 'Каталог',
  catalogViewOrder: 'Ваш Заказ',
  catalogAddProduct: 'Добавить',
  catalogProductUnavailable: 'Закончился',
  welcomeToTheTeaShop: 'Добро пожаловать в ',
  exploreTeaAndCollabs: 'Здесь представлен ассортимент нашего премиального китайского чая и NFT коллекции',
  join: 'Вступай в сообщество ',
  community: '',
  joinCommunity: 'Сообщество',
  catalogSearchBarPlaceholder: 'Введите для поиска',
  viewOrderCommentPlaceholder: 'Введите комментарий',
  orderSearchBarPlaceholder: 'Введите запрос',
  catalogFilterChipAll: 'Все',
  productsNotFound: 'Товары не найдены',
  ordersNotFound: 'Заказы не найдены',
  searchBarHeader: 'ПОИСК',
  commentBarHeader: 'Комментарий к заказу',
  commentBarFooter: 'Добавьте любой дополнительный комментарий к вашему заказу',

  catalogBottomBannerTitle: 'Сообщество 23:23',
  catalogBottomBannerDescription: 'Последние новости и анонсы событий магазина',
  catalogBottomBannerButton: 'Присоединиться',

  orderStatusPaidTimeline: 'Оформлен',
  orderStatusShippedTimeline: 'Отправлен',
  orderStatusDeliveredTimeline: 'Вручен',

  orderFrom: 'Заказ от ',
  yourOrder: 'Ваш заказ',
  editOrder: 'Изменить',
  teaCategory: ' категория',
  payButton: 'Оформить заказ ',
  payOrderButton: 'Оплатить ',
  orderSectionHeader: 'ЗАКАЗ',
  orderNumber: 'Заказ #',
  totalPrice: 'Всего',
  deliverySectionHeader: 'ДОСТАВКА',
  deliveryInformationSectionHeader: 'АДРЕС ДОСТАВКИ',

  receiverSectionHeader: 'ПОЛУЧАТЕЛЬ',
  receiverSectionFooter: 'Вы можете сохранить свою персональную информацию для следующих заказов',

  addressInputPlaceholder: 'Адрес',
  cityInputPlaceholder: 'Город',
  postcodeInputPlaceholder: 'Индекс',
  stateInputPlaceholder: 'Область',
  countryInputPlaceholder: 'Страна',

  phoneNumberInputPlaceholder: 'Номер телефона',
  nameInputPlaceholder: 'Имя',
  emailInputPlaceholder: 'Электронная почта',
  saveInfoSwitchLabel: 'Сохранить информацию',

  ordersAll: 'Все',
  ordersJanuary: 'Январь',
  ordersFebruary: 'Февраль',
  ordersMarch: 'Март',
  ordersApril: 'Апрель',
  ordersMay: 'Май',
  ordersJune: 'Июнь',
  ordersJuly: 'Июль',
  ordersAugust: 'Август',
  ordersSeptember: 'Сентябрь',
  ordersOctober: 'Октябрь',
  ordersNovember: 'Ноябрь',
  ordersDecember: 'Декабрь',

  orderStatusPending: 'В обработке',
  orderStatusPaid: 'Оплачен',
  orderStatusShipped: 'Отправлен',
  orderStatusDelivered: 'Доставлен',
  orderStatusCancelled: 'Отменен',

  mockOrderProductCategory: '4 категория',
  accountBarHeader: 'АККАУНТ',
  otherSettingsBarHeader: 'ПОЛЕЗНОЕ',

  copyWalletAddress: 'Скопировать адрес',
  disconnectWallet: 'Отключить кошелек',

  orderContactQuestionForm: 'Здравствуйте, вопрос по заказу #',

  ourInstagram: 'Наш Instagram',
  orderDetailsPcsOfGood: ' уп. по ',
  weightPrefix: ' г.'

}

export const LocalizedErrorRussianStrings = {
  apiErrorTitle: 'Произошла ошибка:',
  failedLoadGoods: 'Не удалось загрузить список товаров',
  failedLoadCategories: 'Не удалось загрузить список категорий',
  failedLoadCart: 'Не удалось загрузить информацию о вашей корзине',
  failedUpdateCart: 'Не удалось обновить вашу корзину',
  failedLoadDeliveryInfo: 'Не удалось загрузить сохраненную информацию о доставке',
  failedUpdatedDeliveryInfo: 'Не удалось обновить информацию о доставке',
  failedLoadPersonalOrders: 'Не удалось загрузить список Ваших заказов'
}
