import { Button } from '@telegram-apps/telegram-ui'
import { useState } from 'react'

export const HapticTouchBottomButton = (props) => {
  const { isLightTheme, onClick, buttonText, normalStyle, pressedStyle, disabled } = props

  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const handleButtonPress = () => setIsButtonPressed(true)
  const handleButtonRelease = () => setIsButtonPressed(false)

  function timeout (delay) {
    // eslint-disable-next-line promise/param-names
    return new Promise(res => setTimeout(res, delay))
  }

  const sendOnClickWithTimeout = async () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(50) // Вибрация на 50 мс
    }
    await timeout(200)
    onClick()
  }

  return (
        <Button
        type='submit'
        size='l'
        mode='filled'
        stretched
        disabled={disabled}
        onClick={sendOnClickWithTimeout}
        onMouseDown={handleButtonPress}
        onMouseUp={handleButtonRelease}
        onMouseLeave={handleButtonRelease}
        className={isButtonPressed ? pressedStyle : normalStyle}
      >
        {buttonText}
      </Button>
  )
}
