import styled from '@emotion/styled'

// TO DO implement offsets and events like Cell

export const StyledInput = styled.input`
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--tg-theme-text-color);
  font-size: 17px;
  line-height: 22px;
`
