import { List, Section, Cell, Switch, Button, Subheadline, FixedLayout } from '@telegram-apps/telegram-ui'
import { useState, useEffect, useContext } from 'react'
import { orderBox, orderTea } from '../../../../../res/icons/index.js'
import { CatalogPaymentMethodPopUp, HapticTouchBottomButton } from '../../../index.js'
import { get, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { orderSchema } from '../../../../../model/index.js'
import { FormInput } from '../../../../Common/index.js'
import customStorage from '../../../../../CustomStorage.js'
import { TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI, THEME } from '@tonconnect/ui-react'
import { Cell as TonCell } from '@ton/core'
import { beginCell } from '@ton/ton'
import { getEnv, getImageUrl, getCurrencyRegularString, getPriceByCurrency, tonToNanoton, calculateOrderSum, calculateOrderSumByCurrency, ThemeContext } from '../../../../../lib'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useCartStore, useUserStore } from '../../../../../store'
import { cartApi, getGoodsAvailability as getGoodsAvailabilityApi } from '../../../../../api'
import ImageLoader from 'react-imageloader'
import { LocalizedStrings } from '../../../../../res/localization/index.js'
import { ConnectedWalletPopUp } from './Objects/ConnectedWalletPopUp.js'

export const CatalogOrderAddingYourOrderDeliveryDetails = () => {
  const [saveInfo, setSaveInfo] = useState(false)
  const [CartInfo, setCartInfo] = useState([])
  const [selectedMethod, setSelectedMethod] = useState('ton')
  const [isActiveMir, setIsActiveMir] = useState(false) // состояние для управления видимостью попапа
  const [isActiveTon, setIsActiveTon] = useState(true)
  const [isActiveTelegram, setIsActiveTelegram] = useState(false)

  const { deliveryInfo, receiver } = useUserStore(store => store.user)
  const updateDeliveryDetails = useUserStore(store => store.update)

  const paymentMethods = [
    // { id: 'mir', label: 'MIR, SBP, Visa & MasterCard', description: 'Pay with Robokassa, 28+ options', active: setIsActiveMir, isActive: isActiveMir },
    { id: 'ton', label: 'TON', description: 'Pay with TON Connect', active: setIsActiveTon, isActive: isActiveTon }
    // { id: 'telegram', label: 'Telegram Stars', description: 'Pay with Telegram Stars', active: setIsActiveTelegram, isActive: isActiveTelegram }
  ]
  const [currentActiveId, setCurrentActiveId] = useState('ton')
  const { isLightTheme } = useContext(ThemeContext)
  const navigate = useNavigate()

  const [checkGoodsAvailabilityString, setCheckGoodsAvailabilityString] = useState(null)
  const [checkGoodsAvailabilityResult, setCheckGoodsAvailabilityResult] = useState(null)
  const order = useCartStore((state) => state.order)
  const currentOrderId = useCartStore.getState().orderId

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(orderSchema),
    defaultValues: {
      address: '',
      country: '',
      city: '',
      postcode: '',
      // state: '',
      name: '',
      phone: '',
      email: ''
    },
    shouldFocusError: false
  })
  const { setValue } = form

  useEffect(() => {
    if (deliveryInfo) {
      setValue('address', deliveryInfo.address || '')
      setValue('country', deliveryInfo.country || '')
      setValue('city', deliveryInfo.city || '')
      setValue('postcode', deliveryInfo.postcode || '')
      // setValue('state', deliveryInfo.state || '')
    }
    if (receiver) {
      setValue('name', receiver.name || '')
      setValue('phone', receiver.phone || '')
      setValue('email', receiver.email || '')
    }
    setOptions({
      restoreConnection: true,
      language: customStorage.getItem('language'),
      uiPreferences: {
        theme: isLightTheme ? THEME.LIGHT : THEME.DARK,
        borderRadius: 's'
      }
    })
  }, [deliveryInfo, receiver])

  const tonWallet = useTonWallet()
  const TonAddress = useTonAddress()
  // console.log("current Ton wallet", tonWallet, "\ncurrent Ton address: ", TonAddress);
  const [tonConnectUI, setOptions] = useTonConnectUI()
  const [TotalSum, setTotalSum] = useState(0)

  // eslint-disable-next-line prefer-const
  let paymentButtonDisabled = (TonAddress.length === 0 && selectedMethod === 'ton') || checkGoodsAvailabilityResult === false

  function getTransaction () {
    const info = {
      orderId: currentOrderId,
      orderSum: parseFloat(calculateOrderSum(order).toFixed(2))
    }
    const orderInfo = JSON.stringify(info)

    const body = beginCell()
      .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
      .storeStringTail(orderInfo) // write our text comment
      .endCell()

    const transaction = {
      validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes
      messages: [
        {
          address: getEnv().REACT_APP_TRANSACTION_WALLET, // wallet
          amount: tonToNanoton(TotalSum), // Toncoin in nanotons, 0,001 TON here, TODO: add converter from dollar sum to nanotons later; 1 milliard nanoTons (1000000000) = 1 TON
          payload: body.toBoc().toString('base64')
        }
      ]
    }
    console.log('Builded transaction form: ', transaction, orderInfo)
    return transaction
  }

  const checkCartGoodsAvailability = async () => {
    setCheckGoodsAvailabilityString('Started check availability of selected products, please wait...')
    try {
      const response = await getGoodsAvailabilityApi()
      setCheckGoodsAvailabilityString(response.data.message)
      setCheckGoodsAvailabilityResult(response.data.allGoodsAvailable)
      if (response.data.allGoodsAvailable) {
        startPayment()
      }
    } catch (error) {
      setCheckGoodsAvailabilityString('Error occured while check availability of selected products, try open this page again.')
      setCheckGoodsAvailabilityResult(false)
      console.error('Произошла ошибка:', error)
    }
  }

  const finishCart = async () => {
    const sum = calculateOrderSum(order)
    const body = { orderSum: parseFloat(sum.toFixed(2)) }
    console.log('finish cart with body: ', body)

    try {
      await cartApi.finishCart(body)
    } catch (error) {
      console.error('Произошла ошибка при очистке корзины клиента:', error)
    }
  }

  useEffect(() => {
    if (order) {
      setTotalSum(calculateOrderSumByCurrency(order))
      console.log('order', currentOrderId)
    }
    if (currentOrderId && order.length === 0) {
      navigate('/')
    }
  }, [order])

  const BackButton = window.Telegram.WebApp.BackButton
  BackButton.show()
  BackButton.onClick(function () {
    BackButton.hide()
  })
  window.Telegram.WebApp.onEvent('backButtonClicked', function () {
    //  hideDeliveryDetailsPage()
    navigate('/vieworder')
  })

  const onSubmit = async (data) => {
    checkCartGoodsAvailability()

    cartApi.updateOrderDeliveryInfo(data, currentOrderId)
    if (saveInfo) {
      try {
        updateDeliveryDetails(data)
      } catch (error) {
        console.error('Произошла ошибка при обновлении данных:', error)
      }
    }
  }

  const goToOrderPlacedPage = (orderId, orderSum) => {
    finishCart()
    navigate('/orderplaced', { state: { orderId, orderSum } })
  }

  const startPayment = () => {
    const sum = calculateOrderSum(order)
    const orderId = currentOrderId
    const orderSum = parseFloat(sum.toFixed(2))
    if (TonAddress.length > 0) {
      console.log('startPayment')
      try {
        tonConnectUI.sendTransaction(getTransaction()).then(async (result) => {
          const cell = TonCell.fromBase64(result.boc)
          const buffer = cell.hash()
          const hashHex = buffer.toString('hex')
          console.log('Transaction hash: ', hashHex)
          console.log(`Path to see transaction info: ${getEnv().REACT_APP_TON_CONNECT_TRANSACTION_INFO_URL}` + hashHex + '')
          goToOrderPlacedPage(orderId, orderSum)
        })
      } catch (error) {
        console.log('Transaction error: ', error)
      }
    }
  }

  function preloader () {
    return <img height={36} width={36} src={orderTea}></img>
  }

  const handleDisconnect = () => {
    tonConnectUI.disconnect()
  }

  const handleChangeInputFocus = (e, nextIndex) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      document.querySelectorAll('input')[nextIndex].focus()
    }
  }

  return (

    <form
      className={isLightTheme ? styles.MainContentWrapperLight : styles.MainContentWrapperDark}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <List className={styles.MainContent}>

        {order &&
          <Section header={<h2 className={styles.SectionHeader}>{LocalizedStrings.orderSectionHeader}</h2>} className={styles.OrderSectionWrapper}>
            <Cell
              className={styles.OrderInfoCell}
              before={<div alt="box" className={styles.OrderInfoCellIcon}>📦</div>}
            >
              <span className={styles.OrderInfoCellTitle}>
                { LocalizedStrings.orderNumber + currentOrderId}
              </span>
            </Cell>

            {
              order.map((item, index) => (

                <div key={index} className={styles.container}>
    <div className={styles.gridContainer}>
        <div className={styles.flexContainer}>
            <ImageLoader
                imgProps={{ height: 36, width: 36 }}
                src={getImageUrl(item.good.personalImage ? item.good.personalImage : item.good.article.image)}
                className={styles.productImage}
                alt="Product picture"
                preloader={preloader}
            >
                <img height={36} width={36} src={orderTea}></img>
            </ImageLoader>

            <div className={styles.textContainer}>
                <div className={styles.caption}>{item.good.caption}</div>
                <div className={styles.additionalInfo}>
                {item.good.article && (item.good.teaCategory !== null ? (item.good.teaCategory + LocalizedStrings.teaCategory + ' • ') : '') + item.count + LocalizedStrings.orderDetailsPcsOfGood + item.good.addonProps.weight.replace(/\D/g, '') + LocalizedStrings.weightPrefix}
                </div>
            </div>
        </div>
        <div className={styles.price}>
            {getCurrencyRegularString(
              (item.count * getPriceByCurrency(item.good.price)).toFixed(2)
            )}
        </div>
    </div>
 { <div style={{ borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)' }} />}
                </div>
              ))
            }

            { checkGoodsAvailabilityString &&
            <Cell
              style={{ borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)' }}
              before={<div style={{ margin: '12px 0', color: (checkGoodsAvailabilityResult === true ? '#007AFF' : 'red') }}>{checkGoodsAvailabilityString}</div>}
            />
            }
            <Cell
              before={LocalizedStrings.totalPrice}
              after={<span className={styles.OrderSectionTotalPrice}>{getCurrencyRegularString(TotalSum.toFixed(2))}</span>} // replace counting to product.good.price later
              className={styles.OrderSectionTotalWrapper}
            />
          </Section>
        }

        <Section header={<h2 className={styles.SectionHeader}>{LocalizedStrings.deliverySectionHeader}</h2>} >
        <CatalogPaymentMethodPopUp
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
          paymentMethods={paymentMethods}
          currentActiveId={currentActiveId}
          setCurrentActiveId={setCurrentActiveId}
        />
        { TonAddress &&
        <ConnectedWalletPopUp
          walletAddress={TonAddress}
          onDisconnect={handleDisconnect}
        />
        }
        </Section>

        {selectedMethod === 'ton' &&
          <div
            className={styles.TonConnectButtonWrapper}
            onClickCapture={(ev) => {
              ev.preventDefault()
            }}
          >
          { !TonAddress &&
            <TonConnectButton />
          }
          </div>
        }

        <Section
          header={<h2 className={styles.SectionHeader}>{LocalizedStrings.deliveryInformationSectionHeader}</h2>}
          className={styles.DeliveryInformationSectionWrapper}
        >
          <FormInput
            value={form.getValues('country')}
            {...form.register('country')}
            placeholder={LocalizedStrings.countryInputPlaceholder}
            error={form.formState.errors.country?.message || ''}
            onKeyDown={(ev) => { handleChangeInputFocus(ev, 1) }}
          />
          <FormInput
            value={form.getValues('city')}
            {...form.register('city')}
            placeholder={LocalizedStrings.cityInputPlaceholder}
            error={form.formState.errors.city?.message || ''}
            onKeyDown={(ev) => { handleChangeInputFocus(ev, 2) }}
          />
          <FormInput
            value={form.getValues('address')}
            {...form.register('address')}
            placeholder={LocalizedStrings.addressInputPlaceholder}
            error={form.formState.errors.address?.message || ''}
            onKeyDown={(ev) => { handleChangeInputFocus(ev, 3) }}
          />
          <FormInput
            value={form.getValues('postcode')}
            {...form.register('postcode')}
            type="number"
            placeholder={LocalizedStrings.postcodeInputPlaceholder}
            error={form.formState.errors.postcode?.message || ''}
            onKeyDown={(ev) => { handleChangeInputFocus(ev, 4) }}
          />
        </Section>

        <Section
          header={<h2 className={styles.SectionHeader}>{LocalizedStrings.receiverSectionHeader}</h2>}
          footer={<div className={styles.SectionFooter}>{LocalizedStrings.receiverSectionFooter}</div>}
          className={styles.ReceiverInformationSectionWrapper}
          >
          <FormInput
            value={form.getValues('name')}
            {...form.register('name')}
            placeholder={LocalizedStrings.nameInputPlaceholder}
            error={form.formState.errors.name?.message || ''}
            onKeyDown={(ev) => { handleChangeInputFocus(ev, 5) }}
          />
          <FormInput
            value={form.getValues('phone')}
            {...form.register('phone')}
            type="phone"
            placeholder={LocalizedStrings.phoneNumberInputPlaceholder}
            error={form.formState.errors.phone?.message || ''}
            onKeyDown={(ev) => { handleChangeInputFocus(ev, 6) }}
          />
          <FormInput
            value={form.getValues('email')}
            {...form.register('email')}
            placeholder={LocalizedStrings.emailInputPlaceholder}
            error={form.formState.errors.email?.message || ''}
          />
          <Cell
            before={<div className={styles.SwitchSaveInfo}>{LocalizedStrings.saveInfoSwitchLabel}</div>}
            after={<Switch value={saveInfo} onChange={() => setSaveInfo(!saveInfo)} />}
            className={styles.CellSaveInfo}
          />

        </Section>

      </List>

      <FixedLayout>
        <div className={isLightTheme ? styles.PaymentButtonWrapperLight : styles.PaymentButtonWrapperDark}>
          <HapticTouchBottomButton
            onClick={() => { }}
            disabled={paymentButtonDisabled}
            normalStyle={paymentButtonDisabled ? styles.PaymentButtonDisabled : styles.PaymentButton}
            pressedStyle={paymentButtonDisabled ? styles.PaymentButtonDisabled : styles.PaymentButtonPressed}
            buttonText={LocalizedStrings.payOrderButton + getCurrencyRegularString(TotalSum.toFixed(2))}
            />
        </div>
      </FixedLayout>

    </form>
  )
}
