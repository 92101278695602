class CustomStorage {
  constructor () {
    this.storage = {} // Объект для хранения данных
  }

  // Метод для сохранения данных
  setItem (key, value) {
    this.storage[key] = JSON.stringify(value) // Сериализация в строку
  }

  // Метод для получения данных
  getItem (key) {
    if (key in this.storage) {
      return JSON.parse(this.storage[key]) // Десериализация обратно
    }
    return null // Если ключа нет, возвращаем null
  }

  // Метод для удаления данных по ключу
  removeItem (key) {
    delete this.storage[key]
  }

  // Метод для очистки хранилища
  clear () {
    this.storage = {}
  }

  // Опционально: метод для получения всех ключей
  keys () {
    return Object.keys(this.storage)
  }

  // Опционально: получение количества элементов в хранилище
  length () {
    return this.keys().length
  }
}

const customStorage = new CustomStorage()

export default customStorage
