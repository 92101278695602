import styled from '@emotion/styled'

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 13px 16px;
  position: relative;
`
export const FormInputError = styled.p`
  color: var(--tgui--destructive_text_color);
  font-size: 10px;
  position: absolute;
  left: 16px;
  bottom: 1px;
  margin: 0;
`
