import axios from 'axios'
import customStorage from '../CustomStorage'
import { getEnv } from '../lib'

export const api = axios.create({
  baseURL: getEnv().REACT_APP_BACKEND_URL
})

api.interceptors.request.use((config) => {
  config.headers.Authorization = `JWT ${customStorage.getItem('token')}`
  return config
})
