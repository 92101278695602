import { delay } from '../../lib'

/**
 * Registers a form field with custom onChange and onBlur handlers.
 *
 * @param {Object} form - The form object returns with useForm hook
 * @param {string} name - The name of the field to register.
 * @returns {Object} An object containing the registered field's handlers and properties.
 *
 * The custom onChange handler clears field errors and refocuses the field after a delay
 * if any errors are present. It also calls the default onChange handler.
 *
 * The custom onBlur handler calls the default onBlur handler and submits the form if
 * the browser supports the requestSubmit method.
 */

export function registerField (form, name) {
  const register = form.register(name)
  return {
    ...register,
    onChange: (ev) => {
      if (form.formState.errors[name]) {
        form.clearErrors(name)
        delay(10).then(() => form.setFocus(name))
      }
      register.onChange(ev)
    },
    onBlur: (ev) => {
      register.onBlur(ev)
      ev.target.form?.requestSubmit()
    }
  }
}
